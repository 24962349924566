:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-10037{width:100%;}
:root ._ai-center{align-items:center;}
:root ._gap-1316330145{gap:var(--space-11);}
:root ._fd-row{flex-direction:row;}
:root ._fs-1{flex-shrink:1;}
:root ._jc-center{justify-content:center;}
:root ._fg-1{flex-grow:1;}
:root ._rowGap-1481558276{row-gap:var(--space-5);}
:root ._columnGap-1316330145{column-gap:var(--space-11);}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._mt-1481558152{margin-top:var(--space-9);}
:root ._btw-1px{border-top-width:1px;}
:root ._btc-43816293{border-top-color:var(--color-36);}
:root ._pt-1481558152{padding-top:var(--space-9);}
:root ._bts-solid{border-top-style:solid;}
@media screen and (min-width: 993px) { :root:root:root ._fd-_gtMd_1448970769{flex-direction:row-reverse;} }
@media screen and (min-width: 993px) { :root:root:root ._jc-_gtMd_441309761{justify-content:space-between;} }
:root ._ai-stretch{align-items:stretch;}
:root ._gap-1481558152{gap:var(--space-9);}
:root ._mb-1316330145{margin-bottom:var(--space-11);}
@media screen and (min-width: 993px) { :root:root:root ._fd-_gtMd_row{flex-direction:row;} }
@media screen and (min-width: 993px) { :root:root:root ._mb-_gtMd_1481558400{margin-bottom:var(--space-1);} }
@media screen and (min-width: 993px) { :root:root:root ._jc-_gtMd_flex-end{justify-content:flex-end;} }
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
:root ._col-43815425{color:var(--color-43);}