:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._pt-1316330114{padding-top:var(--space-10);}
:root ._pr-1316330114{padding-right:var(--space-10);}
:root ._pb-1316330114{padding-bottom:var(--space-10);}
:root ._pl-1316330114{padding-left:var(--space-10);}
:root ._gap-1316330114{gap:var(--space-10);}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._gap-1481558152{gap:var(--space-9);}
:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-167744090{letter-spacing:var(--f-21-5);}
:root ._fos-229441251{font-size:var(--f-si-5);}
:root ._lh-222976604{line-height:var(--f-li-5);}
:root ._ta-center{text-align:center;}
:root ._w-10037{width:100%;}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._fow-233016047{font-weight:var(--f-we-1);}
:root ._ls-167743966{letter-spacing:var(--f-21-1);}
:root ._fos-229441127{font-size:var(--f-si-1);}
:root ._lh-222976480{line-height:var(--f-li-1);}
:root ._pt-1481558276{padding-top:var(--space-5);}
:root ._pb-1481558276{padding-bottom:var(--space-5);}
:root ._fd-row{flex-direction:row;}
:root ._jc-441309761{justify-content:space-between;}
:root ._maw-10037{max-width:100%;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._textOverflow-ellipsis{text-overflow:ellipsis;}
:root ._whiteSpace-nowrap{white-space:nowrap;}
@media not all and (hover: none) { :root  ._textDecorationLine-0hover-underline:hover{text-decoration-line:underline !important;} }
@media not all and (hover: none) { :root  ._o-0hover-0d0t846:hover{opacity:0.8 !important;} }
:root ._fow-500{font-weight:500;}
:root ._ta-right{text-align:right;}
:root ._textDecorationLine-none{text-decoration-line:none;}
:root ._col-43815425{color:var(--color-43);}
:root ._col-43815456{color:var(--color-42);}
:root ._gap-1316330145{gap:var(--space-11);}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-0px{border-left-width:0px;}
:root ._h-0px{height:0px;}
:root ._mah-0px{max-height:0px;}
:root ._btc-43816200{border-top-color:var(--color-39);}
:root ._brc-43816200{border-right-color:var(--color-39);}
:root ._bbc-43816200{border-bottom-color:var(--color-39);}
:root ._blc-43816200{border-left-color:var(--color-39);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._transform-d0t1394298074{transform:translateY(-0.5px);}
:root ._bg-43816200{background-color:var(--color-39);}
:root ._mt-auto{margin-top:auto;}
:root ._pr-1481558214{padding-right:var(--space-7);}
:root ._pl-1481558214{padding-left:var(--space-7);}
:root ._rowGap-1316330114{row-gap:var(--space-10);}
:root ._columnGap-1316330145{column-gap:var(--space-11);}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._mt-1481558214{margin-top:var(--space-7);}
:root ._fow-233016109{font-weight:var(--f-we-3);}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
:root ._mih-1316330393{min-height:var(--space-19);}
:root ._miw-1316330393{min-width:var(--space-19);}
:root ._pe-none{pointer-events:none !important;}
:root ._w-1316330393{width:var(--space-19);}
:root ._h-1316330393{height:var(--space-19);}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._gap-1316331106{gap:var(--space-21);}