:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._pt-1316330114{padding-top:var(--space-10);}
:root ._pr-1316330114{padding-right:var(--space-10);}
:root ._pb-1316330114{padding-bottom:var(--space-10);}
:root ._pl-1316330114{padding-left:var(--space-10);}
:root ._gap-1316330145{gap:var(--space-11);}
:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-167744152{letter-spacing:var(--f-21-7);}
:root ._fos-229441313{font-size:var(--f-si-7);}
:root ._lh-222976666{line-height:var(--f-li-7);}
:root ._ta-center{text-align:center;}
@media screen and (min-width: 993px) { :root:root:root ._ff-_gtMd_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 993px) { :root:root:root ._fow-_gtMd_600{font-weight:600;} }
@media screen and (min-width: 993px) { :root:root:root ._ls-_gtMd_167744183{letter-spacing:var(--f-21-8);} }
@media screen and (min-width: 993px) { :root:root:root ._fos-_gtMd_229441344{font-size:var(--f-si-8);} }
@media screen and (min-width: 993px) { :root:root:root ._lh-_gtMd_222976697{line-height:var(--f-li-8);} }