:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._t-1481558400{top:var(--space-1);}
:root ._r-1481558276{right:var(--space-5);}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._b-1481558400{bottom:var(--space-1);}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._pr-1481558183{padding-right:var(--space-8);}