:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
@media screen and (min-width: 769px) { :root:root:root ._ff-_gtSm_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 769px) { :root:root:root ._fow-_gtSm_500{font-weight:500;} }
@media screen and (min-width: 769px) { :root:root:root ._ls-_gtSm_167744090{letter-spacing:var(--f-21-5);} }
@media screen and (min-width: 769px) { :root:root:root ._fos-_gtSm_229441251{font-size:var(--f-si-5);} }
@media screen and (min-width: 769px) { :root:root:root ._lh-_gtSm_222976604{line-height:var(--f-li-5);} }
@media screen and (min-width: 1201px) { :root:root:root:root ._ff-_gtLg_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 1201px) { :root:root:root:root ._fow-_gtLg_500{font-weight:500;} }
@media screen and (min-width: 1201px) { :root:root:root:root ._ls-_gtLg_167744121{letter-spacing:var(--f-21-6);} }
@media screen and (min-width: 1201px) { :root:root:root:root ._fos-_gtLg_229441282{font-size:var(--f-si-6);} }
@media screen and (min-width: 1201px) { :root:root:root:root ._lh-_gtLg_222976635{line-height:var(--f-li-6);} }
:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-10037{width:100%;}
:root ._bg-43812573{background-color:var(--color-72);}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._fb-1537{flex-basis:15%;}
:root ._jc-flex-end{justify-content:flex-end;}