:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._w-10037{width:100%;}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._fd-row{flex-direction:row;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._gap-1481558152{gap:var(--space-9);}
:root ._col-675002279{color:var(--color);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-600{font-weight:600;}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._fow-233016109{font-weight:var(--f-we-3);}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
:root ._col-137133982{color:var(--color-6);}
:root ._mt-1481558214{margin-top:var(--space-7);}
:root ._mb-1481558214{margin-bottom:var(--space-7);}