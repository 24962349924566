:root ._pos-relative{position:relative;}
:root ._ai-center{align-items:center;}
:root ._pt-1316330145{padding-top:var(--space-11);}
:root ._pb-1316330145{padding-bottom:var(--space-11);}
:root ._pr-1481558152{padding-right:var(--space-9);}
:root ._pl-1481558152{padding-left:var(--space-9);}
:root ._bg-43812542{background-color:var(--color-73);}
:root ._fs-1{flex-shrink:1;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._jc-441309761{justify-content:space-between;}
:root ._maw-500px{max-width:500px;}
:root ._cur-pointer{cursor:pointer;}
:root ._btc-43815518{border-top-color:var(--color-40);}
:root ._brc-43815518{border-right-color:var(--color-40);}
:root ._bbc-43815518{border-bottom-color:var(--color-40);}
:root ._blc-43815518{border-left-color:var(--color-40);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-10037{width:100%;}
:root ._gap-1481558152{gap:var(--space-9);}
:root ._bg-158523497{background-color:$secondary9;}
:root ._fd-row{flex-direction:row;}
:root ._maw-300px{max-width:300px;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._jc-center{justify-content:center;}
:root ._gap-1316330145{gap:var(--space-11);}
:root ._pt-1481558214{padding-top:var(--space-7);}
:root ._pb-1481558214{padding-bottom:var(--space-7);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._col-43815456{color:var(--color-42);}
:root ._ta-center{text-align:center;}