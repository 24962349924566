:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ov-hidden{overflow:hidden;}
:root ._w-32px{width:32px;}
:root ._h-32px{height:32px;}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._pos-absolute{position:absolute;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._w-24px{width:24px;}
:root ._h-24px{height:24px;}
:root ._zi-982430091{z-index:var(--zIndex-2);}
:root ._b-0px{bottom:0px;}
:root ._r-0px{right:0px;}